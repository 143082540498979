<template>
  <div class="_bg-default px-3">
    <section class="indigo pa-2 rounded d-flex mb-3">
      <img src="../../assets/img/mmeber.svg" height="100px" />
      <div>
        <h1 class="white--text ml-4">Anggota</h1>
      </div>
    </section>
    <v-row class="mb-5">
      <v-progress-linear
        :active="loadTable"
        :indeterminate="loadTable"
        absolute
        top
        color="orange"
      ></v-progress-linear>
      <v-col cols="12" md="2" class="pb-0 mb-0"
        ><label for="event">Pilih Kelompok</label></v-col
      >
      <v-col cols="12" md="10" class="pb-0 mb-0" v-if="allGrup">
        <v-select
          :options="allGrup.data"
          :reduce="nama => nama.id"
          v-model="select_kelompok"
          @search="query => (find = query)"
          label="nama"
          class="white"
          @input="get_group(select_kelompok)"
        ></v-select>
      </v-col>
    </v-row>
    <div v-if="id_group">
      <MemberList v-bind:id_group="id_group" />
    </div>
    <v-dialog v-model="progress" hide-overlay persistent width="250">
      <v-card class="indigo" dark>
        <v-card-text>
          silahkan tunggu...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { NAME_GROUP } from "../../graphql/Group";
import MemberList from "../../components/Mentor/memberList";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: "member",
  apollo: {
    allGrup: {
      query: NAME_GROUP,
      variables() {
        return { page: 1, first: 10, search: this.find };
      }
    }
  },
  computed: {
    loadTable() {
      return this.$apollo.queries.allGrup.loading;
    }
  },
  components: {
    MemberList,
    vSelect
  },
  data() {
    return {
      progress: false,
      search: "",
      find: "",
      src: false,
      select_kelompok: null,
      id_group: null
    };
  },
  methods: {
    get_group(id) {
      this.src = false;
      this.id_group = id;
    },
    srcBlur() {
      document.activeElement.blur();
      this.src = false;
    }
  }
};
</script>

<style scopd>
._bg-default {
  background: #f4f6f9;
  min-height: 90vh;
}
</style>
