<template>
  <div>
    <v-card class="temp-table">
      <v-card-title>
        Daftar anggota
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <div>
        <v-data-table
          :loading="loadTable"
          :headers="headers"
          :items="grupById.user"
          :search="search"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-fle">
              <v-btn fab dark small color="cyan">
                <v-icon dark small @click="editPage(item)"> mdi-pencil </v-icon>
              </v-btn>
              <v-btn
                fab
                dark
                small
                color="red"
                class="ml-3"
                @click.prevent="deleteConfirm(item)"
              >
                <v-icon dark small> mdi-delete </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <EditPeserta
      v-bind:profile="profile"
      v-bind:idProfile="idProfile"
      v-bind:dialogView="dialogView"
      v-bind:compareEmail="compareEmail"
      v-on:false="dialogFalse()"
    />
  </div>
</template>

<script>
import { GROUP_BY_ID } from "../../graphql/Event";
import EditPeserta from "../Teacher/Modal/editStudent";

export default {
  name: "memberList",
  apollo: {
    grupById: {
      variables() {
        return { id: this.id_group };
      },
      skip() {
        return !this.id_group;
      },
      query: GROUP_BY_ID,
      error(error) {
        console.error(error);
      }
    }
  },
  props: ["id_group"],
  components: {
    EditPeserta
  },
  computed: {
    loadTable() {
      return this.$apollo.queries.grupById.loading;
    },
    idProfile() {
      let id = null;
      if (!this.$apollo.queries.grupById.loading) {
        id = this.grupById.mentor.id;
      }
      return id;
    }
  },
  data() {
    return {
      grupById: [],
      search: "",
      dialogView: false,
      profile: [],
      compareEmail: "",
      headers: [
        { text: "id", value: "id" },
        {
          text: "Nama Lengkap",
          align: "start",
          sortable: false,
          value: "nama_lengkap"
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email"
        },
        { text: "Aksi", value: "actions" }
      ]
    };
  },
  methods: {
    editPage(item) {
      this.profile = item;
      this.compareEmail = item.email;
      this.dialogView = true;
    },
    dialogFalse() {
      this.dialogView = false;
      //   this.refetchGroup();
    }
  }
};
</script>

<style></style>
